@import "variables.scss";

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none;
}

body {
  height: calc(100vh);
  background-attachment: fixed;
  background-color: $black;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  font-family: $body-font;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}

main.app {
  height: 100vh;
  
}

img {
  max-width: 100%;
}

a {
  color: $white;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $accent-one;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  font-weight: 300;
}

h1 {
  font-size: 3.5rem !important;
}

h2 {
  font-size: 2.75rem !important;
}

h3 {
  font-size: 1.25rem !important;
}

p {
  line-height: 1.5;
  margin-bottom: 1rem;
}

strong {
  font-weight: 700;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 2;

  article {
    display: flex;
    overflow: hidden;
  }
}

// .row {
//   display: flex;

//   .col {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 2;
//     width: 50%;
//   }
// }

header {
  display: flex;
  flex-direction: column;
  width: 100vw;

  .top-bar {
    background: rgba(45, 51, 67, 0.7);
    color: $gray-blue;
    display: flex;
    justify-content: center;
    margin-bottom: $gutter;
    padding: calc($gutter/2);
    width: 100vw;

    strong {
      color: $white;
      margin-left: 4px;
    }
  }

  .logo {
    align-self: flex-start;
    margin: 0 calc($gutter * 2);
  }
}

.footer {
  // align-items: center;
  color: $dark-gray;
  // display: flex;
  // flex-direction: column;
  padding: calc($gutter/2) $gutter;
  position: relative;
  // justify-content: center;
}

@media (max-width: 800px) {
  header .top-bar {
    display: block;
    line-height: 1.5rem;
  }

  .mobile-flex {
    display: block !important;
  }
}

@media (max-width: 660px) {
  header .logo {
    align-self: center;
    width: 75%;
  }

  .mobile-flex {
    display: block !important;
  }
}

.d-flex {
  display: flex !important;
}

.justify-content-end {
  justify-content: end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.mx-3 {
  margin-right: 10px;
  margin-left: 10px;
}

.footer-link:hover {
  color: #002cde;
}

.card {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 1rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: #000000;
  padding: 1.25rem;
}

.mobile-eco-text {
  margin-left: 10%;
}

@media (min-width: 640px) {
  .card {
    width: 15rem;
  }
}

@media only screen and (max-width: 600px) {
  .row .col {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    width: 100%;
  }

  .footer-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .mobile-eco-text {
    margin-left: 0;
  }

  main.app {
    width: 90%;
    margin-left : 5%;
  }

  main {
    margin: 10px;
  }
}

.text-3xl {
  font-size: 2.875rem;
  line-height: 2.25rem;
}

.c-image {
  display: flex;
  border-radius: 1rem;
  --tw-bg-opacity: 1;
}

@media only screen and (max-width: 600px) {
  .text-sm {
    font-size: 0.55rem;
    line-height: 1.25rem;
  }
}

.fs-30 {
  font-size: 30px !important;
}

.maintenance {
  // background-image: url(https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.maintenance {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.maintenance {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.maintenance_contain {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
}
.maintenance_contain img {
  width: auto;
  max-width: 100%;
}

//new pages

.social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.social a {
  background-color: #000;
  background-position: center 20px;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 360px;
  justify-content: flex-end;
  margin: 2%;
  padding: 20px;
  width: 28%;
}

.casino-hover,
.poker-hover .crypto-hover,
.daisi-hover,
.academy-hover,
.hex-hover,
.digital-hover,
.nft-hover,
.podcast-hover,
.social-hover,
.tv-hover,
.utility-hover,
.game-hover,
.defi-hover,
.chain-hover,
.channels-hover
{
  position: relative;
}

.social .social-hover {
  background-image: url("../assets/social-img.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
}

.social .chat-hover {
  background-image: url("../assets/chat.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
  position: relative;
}

.social .meet-hover {
  background-image: url("../assets/Meet.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
  position: relative;
}

.social .services-hover {
  background-image: url("../assets/agency.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
  position: relative;
}

.social .uitility-hover {
  background-image: url("../assets/blockstar-uitility.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
  position: relative;
}

.social .tv-hover {
  background-image: url("../assets/tv-img.png");
  background-size: 250px !important;
  border: 1px solid #c30101;
}

.social .digital-hover {
  background-image: url("../assets/music-img.png");
  background-size: 250px !important;
  border: 1px solid #e37b08;
}

.social .channels-hover {
  background-image: url("../assets/channels.png");
  background-size: 250px !important;
  border: 1px solid #012fdd;
}

.social .academy-hover {
  background-image: url("../assets/academy-icon.png");
  background-size: 250px !important;
  border: 1px solid #6f767c;
}

.social .crypto-hover {
  background-image: url("../assets/crypto.png");
  background-size: 250px !important;
  border: 1px solid #00a94d;
  position: relative;
}

.social .altitude-hover {
  background-image: url("../assets/Altitude.png");
  background-size: 250px !important;
  border: 1px solid #00a94d;
  position: relative;
}

.social .markets-hover {
  background-image: url("../assets/markets.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
  position: relative;
}

.social .casino-hover {
  background-image: url("../assets/casino.png");
  background-size: 250px !important;
  border: 1px solid #fc6cc2;
}

.social .poker-hover {
  background-image: url("../assets/poker.png");
  background-size: 250px !important;
  border: 1px solid #245068;
}

.social .games-hover {
  background-image: url("../assets/games.png");
  background-size: 250px !important;
  border: 1px solid #002de1;
}

.social .daisi-hover {
  background-image: url("../assets/daisi-icon.png");
  background-size: 250px !important;
  border: 1px solid #0ec1e1;
}

.social .create-hover {
  background-image: url("../assets/Create.png");
  background-size: 250px !important;
  border: 1px solid #0ec1e1;
  position: relative;
}

.social .imageine-hover {
  background-image: url("../assets/imageine.png");
  background-size: 250px !important;
  border: 1px solid #0ec1e1;
  position: relative;
}

.social .kids-hover {
  background-image: url("../assets/smartanimal.png");
  background-size: 250px !important;
  border: 1px solid #0ec1e1;
  position: relative;
}

.social .ai-agent {
  background-image: url("../assets/ai-agent.png");
  background-size: 250px !important;
  border: 1px solid #0ec1e1;
  position: relative;
}

.social .hex-hover {
  background-image: url("../assets/hex.png");
  background-size: 250px !important;
  border: 1px solid #8400d1;
}
.social .podcast-hover {
  background-image: url("../assets/podcast-logo.png");
  background-size: 250px !important;
  border: 1px solid #ffe700;
}
.social .utility-hover {
  background-image: url("../assets/uitility.png");
  background-size: 250px !important;
  border: 1px solid #002fdd;
}

.social .upload-hover {
  background-image: url("../assets/Upload.png");
  background-size: 250px !important;
  border: 1px solid #002fdd;
  position: relative;
}

.social .crm-hover {
  background-image: url("../assets/CRM.png");
  background-size: 250px !important;
  border: 1px solid #002fdd;
  position: relative;
}

.social .store-hover {
  background-image: url("../assets/store.png");
  background-size: 250px !important;
  border: 1px solid #002fdd;
  position: relative;
}

.social .game-hover {
  background-image: url("../assets/games.png");
  background-size: 250px !important;
  border: 1px solid #245068;
}

.social .defi-hover {
  background-image: url("../assets/defi.png");
  background-size: 250px !important;
  border: 1px solid #00a94d;
  position: relative;
}

.social .chain-hover {
  background-image: url("../assets/chain.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .launchpad-hover {
  background-image: url("../assets/launchpad.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .nft-hover {
  background-image: url("../assets/nft.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .staking-hover {
  background-image: url("../assets/staking.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .dex-hover {
  background-image: url("../assets/dex.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .synergy-hover {
  background-image: url("../assets/synergy.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .dao-hover {
  background-image: url("../assets/dao.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .mainnet-hover {
  background-image: url("../assets/mainnet.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social .testnet-hover {
  background-image: url("../assets/testnet.png");
  background-size: 250px !important;
  border: 1px solid #fff;
  position: relative;
}

.social a:hover {
  background-color: #000;
  background-position: center 20px;
  background-repeat: no-repeat;
  border-color: #fff;
  color: #fff;
  cursor: pointer;
}

.land-img {
  display: flex;
  justify-content: start;
  height: 50px;
  margin-top: 50px;
  margin-bottom: 30px;
  
}

.soon-text {
  font-size: 40px;
  margin-top: 5px;
}

@media (max-width: 1400px) {
  .social a {
    width: 46%;
  }
}

@media (max-width: 780px) {
  .social a {
    width: 100%;

    &:nth-child(odd) {
      margin-right: 2%;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }
}

.mobile-bottom-nav {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .mobile-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    will-change: transform;
    transform: translateZ(0);
    display: flex;
    height: 50px;
    box-shadow: 0 -2px 5px -2px #333;
    background-color: #2b2b2b;
  }
  .mobile-bottom-nav__item {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
  .mobile-bottom-nav__item--active {
    color: red;
  }
  .mobile-bottom-nav__item-content {
    display: flex;
    flex-direction: column;
  }

  .mobile-menu-item {
    display: none !important;
  }
}

.brand-assets-main{
  width: 60%;
}


.card-a {
  background-color: #000;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 2% 2% 0;
  padding: 20px;
  text-align: center;
  width: 100%;
}


.custom-card {
  height: 400px;
  background-color: #000000;
  color: #fff;
  padding : 5px !important;
  width: auto !important;
}

/* Image height to synchronize */
.card-img-top {
  height: 200px;
  object-fit: cover;
}

// .ribbon {
//   width: 150px;
//   height: 150px;
//   overflow: hidden;
//   position: absolute;
// }

.ribbon-index span {
//   position: absolute;
//   display: block;
  width: 225px !important;
//   padding: 15px 0;
//   box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
//   color: #fff;
//   font: 700 18px/1 'Lato', sans-serif;
//   text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
//   text-transform: uppercase;
//   text-align: center;
}

.ribbon-top-left-index span {
  right: -14px !important;
  top: 5px !important;
  transform: rotate(-45deg);
}


// .ribbon-bottom-right {
//   bottom: -0px;
//   right: -0px;
// }

// .ribbon-bottom-right span {
//   left: -14px;
//   bottom: 5px;
//   transform: rotate(-225deg);
// }

.custom-card:hover{
  border: 1px solid #fff !important;
}


.about-card{
  height: max-content !important;
  border: 1px solid #fff !important;
}

@media (max-width: 1080px) {
  .brand-assets-main{
    width: 100%;
  }
  
}

@media (max-width: 600px) {

  .ecosystem a{
    height: 270px;
  }
}

@media (min-width: 780px) and (max-width: 1000px) {
  .social a {
    height: 450px;
  }
}

@media (max-width: 450px) {
  .social a {
    margin-bottom: calc($gutter/2);
    width: 100%;
    height: 450px;

    &:nth-child(odd),
    &:nth-child(even) {
      margin-right: 0;
    }
  }
}

