@import "variables.scss";

.ecosystem {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    background-color: $accent-two;
    background-position: center 20px;
    background-repeat: no-repeat;
    border-radius: 10px;
    height: 300px;
    justify-content: flex-start;
    margin: 2%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 28%;
    background-color: #000000;
    cursor: pointer;

    
    &.music:hover {
      // background-color: $orange;
    }

    h2 {
      color: #fff;
      font-weight: 300;
      margin: 0;
    }

    p {
      color: $transWhite;
      display: block;
      font-size: 0.8rem;
      margin: 0;
    }


    // &:nth-child(3),
    // &:nth-child(6) {
    //   // margin-right: 0;
    // }

    &:hover {
      background-color: $accent-one;
      background-image: url("../assets/logo-icon.svg");
      background-repeat: no-repeat;
      background-position: center 20px;
      cursor: pointer;
      border-color: #FFF;

      h2 {
        color: $white;
      }

      p {
        color: $white;
      }


    }
  }

  @media (max-width: 1200px) {
    a {
      background: $accent-one;
      background-image: url("../assets/logo-icon.svg");
      background-repeat: no-repeat;
      background-position: center 20px;
      justify-content: flex-end;
      height: 350px;
      width: 42%;

      h2 {
        color: $white;
      }

      p {
        display: block;
      }
    }
  }

  @media (max-width: 600px) {
    a {
      width: 100%;
    }
  }
}

.social-hover {
  
  border: 1px solid #002de1;
  position: relative;
  background-size: 150px !important;
}

.entertainment-hover {
  
  border: 1px solid #c30101;
  position: relative;
  background-size: 150px !important;
}

.ai-hover {
  
  border: 1px solid #0ec1e1;
  position: relative;
  background-size: 150px !important;
}

.finance-hover {
  
  border: 1px solid #00a94d;
  position: relative;
  background-size: 150px !important;
}

.gaming-hover {
  
  border: 1px solid #b05700;
  position: relative;
  background-size: 150px !important;
}

.shopping-hover {
  
  border: 1px solid #b839ff;
  position: relative;
  background-size: 150px !important;
}

.tools-hover {
  
  border: 1px solid #7a1824;
  position: relative;
  background-size: 150px !important;
}

.learning-hover {
  
  border: 1px solid #585f67;
  position: relative;
  background-size: 150px !important;
}

.kids--hover {
  
  border: 1px solid #fce628;
  position: relative;
  background-size: 150px !important;
}


.ribbon-top-right {
  top: 0px;
  right: 0px;
}

.ribbon {
  width: 130px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  // border: 5px solid #2980b9;
}

.ribbon-top-right span {
  left: -18px;
  top: 30px;
  transform: rotate(45deg);
}

.ribbon span {
  position: absolute;
  display: block;
  width: 255px;
  padding: 10px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 400 13px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}


.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  // border: 5px solid #2980b9;
}

.social-hover,
.nft-hover,
.tv-hover,
.music-hover,
.daisi-hover,
.utility-hover,
.podcast-hover,
.hex-hover,
.casino-hover,
.poker-hover,
.cate-hover,
.games-hover {
  position: relative;
}

.span-social, .span-white.span-social {
  background-color: #002fdd;
}

.span-crypto, .span-white.span-crypto {
  background-color: #00a94d;
}

.span-tv, .span-white.span-tv {
  background-color: #c30101;
}

.span-digital, .span-white.span-digital {
  background-color: #e37b08;
}

.span-daisi, .span-white.span-daisi {
  background-color: #0ec1e1;
}
.span-utility, .span-white.span-utility {
  background-color: #002fdd;
}
.span-white.span-utility {
  background-color: #b839ff;
}
.span-white.span-tools {
  background-color: #7a1824;
}
.span-white.span-kids {
  background-color: #fce628;
}

.span-podcast, .span-white.span-podcast {
  background-color: #ffe700;
  color: #000 !important;
}

.span-hex {
  background-color: #8400d1;
}

.span-white.span-hex{
  background-color: #00a94d;
}

.span-casino, .span-white.span-casino {
  background-color: #ff69c6;
}
.span-white.span-casino {
  background-color: #b05700;
}

.span-poker, .span-white.span-poker {
  background-color: #245068;
}

.span-games, .span-white.span-games {
  background-color: #0030dd;
}

.span-academy, .span-white.span-academy {
  background-color: #6f767e;
}

.span-white {
  background-color: #ffffff;
  color: #000 !important;
}


.ribbon-top-left {
  top: 0px;
  left: 0px;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}


.ribbon-top-left-main {
  top: 0px;
  left: 0px;
}

.ribbon-top-left-main::before {
  top: 0;
  right: 0;
}

.ribbon-bottom-right {
  bottom: 0px;
  right: 0px;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left span {
  right: -40px;
  top: 29px;
  transform: rotate(-45deg);
}

.ribbon-top-left-main span{
  right: -29px;
  top: 7px;
  transform: rotate(-45deg);
}
.ribbon-bottom-right span {
  left: -20px;
  bottom: -7px;
  transform: rotate(-45deg);
}


.ribbon1 span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 11px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 400 13px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.utility a {
  width: 40%;
}

// .utility a p {
//   text-align: center;
//   margin-bottom: 30px;
//   font-size: 36px;
//   font-weight: 600;
// }

.podcast,
.utility {
  margin-top: 70px !important;
}

.podcast a {
  width: 40%;
}

.podcast a p {
  text-align: center;
  margin-bottom: 10px;
  //   font-size: 32px;
  //   font-weight: 600;
}



@media (min-width: 600px) {
  .podcast a {
    margin-bottom: 0.35rem;
    width: 40%;
  }

  .utility a {
    margin-bottom: 0.35rem;
    width: 40%;
  }


}

@media only screen and (min-width: 992px) {
  .podcast a {
    margin-bottom: 0.35rem;
    width: 30%;
  }

  .utility a {
    margin-bottom: 0.35rem;
    width: 30%;
  }
}

@media only screen and (min-width: 1200px) {
  .podcast a {
    margin-bottom: 0.35rem;
    width: 20%;
  }

  .utility a {
    margin-bottom: 0.35rem;
    width: 23%;
  }
}


@media (max-width: 600px) {
  .podcast a {
    margin-bottom: 0.35rem;
    width: 90%;
  }

  .utility a {
    margin-bottom: 0.35rem;
    width: 90%;
  }

  .ecosystem a p{
    padding-bottom : 20px;
  }
}