body, html {
    margin: 0;
    height: 100%;
  }

  iframe {
    display: block;      /* Ensure there's no whitespace */
    width: 100%;         /* Full width */
    height: 750px;       /* Full height of the viewport */
    border: none;        /* Remove default border */
  }

  @media only screen and (max-width: 600px) {
    iframe {
      height: 100%;
    }
  } 