@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&family=Teko:wght@300&display=swap");

$heading-font: "Teko", sans-serif;
$body-font: "Lato", sans-serif;

$gutter: 0.7rem;

$black: #000;
$white: #fff;
$transWhite: rgba(255, 255, 255, 0.7);

// Layout Styles
$dark-gray: #788195;
$gray: #949caf;
$light-gray: #e6e1e1;

// Accent Colors
$accent-one: #000000;
$accent-two: #2c3343;
$gray-blue: #7f879b;
$orange: #ff9c50;

// Gradient Styles
$linear-gradient: linear-gradient(0deg, $accent-one 0%, $accent-two 100%);
$reverse-linear-gradient: linear-gradient(
  0deg,
  $accent-two 0%,
  $accent-one 100%
);
$radial-gradient: radial-gradient(circle, $accent-one 0%, $accent-two 60%);
