.card {
    position: relative;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    height: 500px;
    overflow: hidden;
    width: 400px;
    color: #fff;
}

.card>figure {
    width: 90%;
    margin: 20px auto 0 auto;
}

.card>figure>img {
    width: 100%;
}

.details {
    background-color: #000000;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    top: 85%;
    width: 100%;
    transition: box-shadow .3s ease-in-out,
        top .3s ease-in-out;
}

.card:hover .details {
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
    top: 80%;
}

.details>.min-details,
.details>.options,
.details>.options>.options-size,
.details>.options>.options-colors {
    margin-bottom: 20px;
}

.details>.min-details {
    display: flex;
    justify-content: space-between;
}

.details>.min-details>h1>span {
    color: #7B7B7B;
    display: block;
    font-size: .9rem;
    font-weight: 400;
}

.details>.options h1 {
    margin-bottom: 5px;
}

.details>.options ul {
    list-style-type: none;
    display: flex;
}

.details>.options ul li {
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
}

.options-size>ul>li {
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFF;
    font-size: .7rem;
    font-weight: 900;
    text-transform: uppercase;
    transition: background-color .3s ease-in-out;
}

.options-size>ul>li:hover {
    background-color: #192a56;
}

.options-colors>ul>li {
    border: none;
}

.options-colors>ul>li:nth-child(1) {
    background-color: #FF1;
}

.options-colors>ul>li:nth-child(2) {
    background-color: #000;
}

.options-colors>ul>li:nth-child(3) {
    background-color: #FB0000;
}

.options-colors>ul>li:nth-child(4) {
    background-color: #FF69B4;
}

.options-colors>ul>li:nth-child(5) {
    background-color: #192a56;
}

.game-btn {
    background-color: #192a56;
    border-radius: 5px;
    color: #FFF;
    display: block;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    width: 100%;
    transition: box-shadow .3s ease-in-out,
        transform .3s ease-in-out;
}

.game-btn:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    transform: translateY(-2px);
}


/* Basic row setup */
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    /* Include padding in the total width calculation */
}

/* Full width for mobile screens */
.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

/* Two columns width (2/12 of the container, or ~16.67%) */
.col-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
}

/* Three columns width (3/12 of the container, or ~25%) */
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

/* Add responsive support for larger screens */
@media (min-width: 768px) {
    .col-md-2 {
        flex: 0 0 16.6667%;
        max-width: 16.6667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}


.tf-create {
    background: linear-gradient(156.67deg, hsla(240, 1%, 44%, .4) .07%, rgba(100, 97, 113, .148) 99.07%);
    border-radius: 25px;
    transition: all .4s ease;
}

.tf-create .title {
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    margin: 16px;
    text-align: center;
}

.icon img {
    border-radius: 25px;
}

.tf-create:hover {
    box-shadow: 0 8px 16px rgba(4, 10, 45, .3);
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
}

.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* Stack the message and the link vertically */
    height: 100vh;
    /* Full viewport height */
    text-align: center;
}

.not-found {
    font-size: 4rem;
    /* Increase the font size */
    color: #fff;
    /* Color for the "No Game Found" message */
    margin-bottom: 20px;
    /* Add some space between the text and the link */
}

.go-back {
    text-decoration: none;
    /* Remove underline from the link */
    color: #007BFF;
    /* Set link color */
    font-size: 1.2rem;
    /* Increase font size */
    border: 1px solid #007BFF;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s, color 0.3s;
    /* Smooth hover effect */
}

.go-back:hover {
    background-color: #007BFF;
    /* Blue background on hover */
    color: #fff;
    /* White text on hover */
}